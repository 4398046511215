input.prompt {
  border: none !important;
  font-size: 22px !important;
  line-height: 40px;
  font-family: "Rubik";
  letter-spacing: -0.69px;
}

.searchBar {
  box-sizing: border-box;
  height: 4.5rem;
  width: 100%;
}

.searchBar input {
  background: transparent !important;
  height: 4.5rem !important;
  padding-right: 0 !important;
  padding-left: 5.125rem !important;
}

.searchBar.small input {
  background: transparent !important;
  height: 4.5rem !important;
  padding-right: 0 !important;
  padding-left: 3.25rem !important;
}

.searchBar i {
  left: 28px;
  background: url("images/new/2-icon_search.svg") no-repeat;
  background-size: contain;
  background-position: center;
  height: 30px !important;
  border-radius: 0px !important;
  top: 1.3rem !important;
  width: 42px !important;
}

.searchBar.small i {
  left: 8px;
  background: url("images/new/2-icon_search.svg") no-repeat;
  background-size: contain;
  background-position: center;
  height: 30px !important;
  border-radius: 0px !important;
  top: 1.3rem !important;
  width: 42px !important;
}

.searchBar.xs i {
  left: 8px;
  background: url("images/new/2-icon_search.svg") no-repeat;
  background-size: contain;
  background-position: center;
  height: 20px !important;
  border-radius: 0px !important;
  top: 1.7rem !important;
  width: 42px !important;
}

.searchBar i::before {
  visibility: hidden;
}

.searchBar i::after {
  visibility: hidden;
}

.ui.search > .results {
  background-color: #037792;
  margin-top: 0;
  border-top-right-radius: 0;
}

.ui.search > .results .result:hover {
  background-color: #037792;
}

.ui.search > .results .result .title {
  @apply font-rubik sm:text-[22px] text-base sm:leading-[40px] leading-[24px] sm:tracking-[-0.69px] tracking-[-0.5px];
  color: white;
}

.ui.search > .results .result {
  border: 0;
}

.ui.search > .results .result .title:hover {
  color: #000;
}
/* 
.ui.search > .results::before {
  content: "";
  background-color: #037792 !important;
  position: absolute !important;
  margin-left: -500px !important;
  height: 100% !important;
  width: 100% !important;
  z-index: -1 !important;
} 
*/

.ui.label {
  background: #007a94 !important;
  border-radius: 26.5419px !important;
}

.ui.green.label,
.ui.green.labels .label {
  background-color: #007a94 !important;
}

.ui.green.label,
.ui.green.labels .label:hover {
  background-color: #007a94 !important;
}
/* 
.searchBar::before {
  background-color: #037792;
  position: absolute;
  margin-left: -500px;
  height: 100%;
  width: 50%;
} */
